import SelectBankAccount from '@fingo/lib/components/selects/SelectBankAccount';
import { ExternalOfferShoppingCart } from '@fingo/lib/components/shopping-cart';
import { useCreateOperation, useGetCountryFromUrl } from '@fingo/lib/hooks';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { currencyVar } from '@fingo/lib/apollo/reactive-variables';
import BySignatureOfferCartMessage from '../BySignatureOfferCartMessage';
import ShoppingCartStepperNavigation from '../../ShoppingCartStepperNaviagtion';
import ShoppingCartSummaryActionButton from '../../ShoppingCartSummary/ShoppingCartSummaryActionButton';

const ExternalCessionStep = ({ prevStep, nextStep }) => {
  const country = useGetCountryFromUrl();
  const [bankAccountId, setBankAccountId] = useState();
  const currency = useReactiveVar(currencyVar);
  const [createOperation, { loading }] = useCreateOperation(
    true, // isExternal
    bankAccountId, // bankId
    null, // certificatePassword
    nextStep, // onCompleted
    null, // setError
    true, // skipCleaning
  );
  return (
    <Stack flexGrow={1} spacing={2}>
      {country.name === 'Mexico' ? (
        <>
          <BySignatureOfferCartMessage />
          <SelectBankAccount
            bankId={bankAccountId}
            setBankId={setBankAccountId}
            currency={currency}
          />
        </>
      ) : (
        <ExternalOfferShoppingCart
          bankId={bankAccountId}
          setBankId={setBankAccountId}
        />
      )}
      <ShoppingCartStepperNavigation onBack={prevStep}>
        <ShoppingCartSummaryActionButton
          id="confirm-external-cession-button"
          label="Operar con Fingo"
          disabled={!bankAccountId}
          loading={loading}
          onClick={createOperation}
        />
      </ShoppingCartStepperNavigation>
    </Stack>
  );
};

ExternalCessionStep.propTypes = {
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default ExternalCessionStep;
