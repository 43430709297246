import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useReactiveVar } from '@apollo/client';
import { useSelectedCompany } from '@fingo/lib/hooks';
import { shoppingCartOffersVar } from '@fingo/lib/apollo/reactive-variables';
import { INVOICE_WITH_DOCUMENT } from '@fingo/lib/graphql';
import SummaryStep from './steps/SummaryStep';
import ShoppingCartStepper from '../ShoppingCartStepper';
import ConfirmationStep from './steps/ConfirmationStep';
import CredentialProviderSelectorStep from './steps/CredentialProviderSelectorStep';
import CessionTypeSelectorStep from './steps/CessionTypeSelectorStep';
import CredentialProviderCredentialsStep from './steps/CredentialProviderCredentialsStep';
import InternalCessionStep from './steps/InternalCessionStep';
import ExternalCessionStep from './steps/ExternalCessionStep';
import UploadDocumentsStep from './steps/UploadDocumentsStep';
import { getCurrentCredentialProvider } from '../../../features/profile/helpers/credentials';
import BulletList from '../../lists/BulletList';

const OfferShoppingCart = ({ toggleMenu }) => {
  const company = useSelectedCompany();
  const currentProvider = useMemo(
    () => getCurrentCredentialProvider(company),
    [company],
  );
  const [selectedProvider, setSelectedProvider] = useState(currentProvider);
  const [selectedCessionType, setSelectedCessionType] = useState('internal');
  const shoppingCartOffers = useReactiveVar(shoppingCartOffersVar);
  const invoiceIds = useMemo(
    () => shoppingCartOffers.map((offer) => offer.invoiceId),
    [shoppingCartOffers],
  );
  const { data, loading } = useQuery(INVOICE_WITH_DOCUMENT, {
    variables: { id_In: invoiceIds },
    skip: invoiceIds?.length === 0,
  });

  const invoices = useMemo(
    () => data?.invoices.edges.map((edge) => {
      const invoice = edge.node;
      const hasXMLDocument = invoice.documents.some(
        (doc) => doc.type.code === 'XML',
      );
      return { ...invoice, hasXMLDocument };
    }) || [],
    [data],
  );

  const allDocumentsHaveXML = useMemo(
    () => invoices.every((invoice) => invoice.hasXMLDocument),
    [invoices],
  );

  const { invoiceProviderCredentials = [] } = company;

  const hasValidCredentialsForProvider = useMemo(
    () => invoiceProviderCredentials.some(
      (credential) => credential.credentialProvider.id === selectedProvider?.id,
    ),
    [selectedProvider, invoiceProviderCredentials],
  );

  const shouldShowCredentialStep = useMemo(() => {
    if (selectedProvider?.name === 'MiPyme' || !selectedProvider?.integrated) {
      return false;
    }
    if (selectedCessionType === 'external') {
      return false;
    }
    return !hasValidCredentialsForProvider;
  }, [selectedProvider, selectedCessionType]);

  const shouldShowUploadDocumentsStep = useMemo(() => {
    if (selectedCessionType === 'external') {
      return false;
    }
    return !selectedProvider?.integrated && !allDocumentsHaveXML;
  }, [selectedProvider, selectedCessionType, allDocumentsHaveXML]);

  const steps = useMemo(() => {
    if (!data) return [];
    const stepsArray = [
      {
        id: 'summary-step',
        title: 'Resumen de operación',
        component: SummaryStep,
      },
      {
        id: 'credential-provider-selector-step',
        title: 'Cede tus facturas',
        tooltipTitle:
          'Según el facturador que utilices, te ofreceremos las opciones disponibles para ceder tus facturas.',
        component: CredentialProviderSelectorStep,
        props: {
          selectedProvider,
          setSelectedProvider,
        },
      },
      {
        id: 'cession-type-selector-step',
        component: CessionTypeSelectorStep,
        title: 'Cede tus facturas',
        tooltipTitle: (
          <BulletList
            title="Te recomendamos ceder desde Fingo:"
            titleProps={{ fontSize: 'inherit' }}
            items={[
              {
                id: 1,
                primary: 'Es más rápido, cede con un click',
                color: 'inherit',
              },
              {
                id: 2,
                primary: 'Más fácil, no rellenas formularios',
                color: 'inherit',
              },
              {
                id: 3,
                primary: 'Garantizamos seguridad en todo momento',
                color: 'inherit',
              },
            ]}
            disablePadding
            dense
          />
        ),
        props: {
          selectedCessionType,
          setSelectedCessionType,
          selectedProvider,
          allDocumentsHaveXML,
        },
      },
    ];

    if (shouldShowCredentialStep) {
      stepsArray.push({
        id: 'credential-provider-credentials-step',
        title: 'Cede tus facturas',
        tooltipTitle:
          'Si olvidaste los datos de tu cuenta, acude a tu facturador para recuperarlos',
        component: CredentialProviderCredentialsStep,
        props: { selectedProvider },
      });
    }

    if (shouldShowUploadDocumentsStep) {
      stepsArray.push({
        id: 'upload-documents-step',
        title: 'Cede tus facturas',
        tooltipTitle:
          'El XML es un archivo descargable de tu facturador con información de tu factura',
        component: UploadDocumentsStep,
        props: { invoices },
      });
    }

    if (selectedCessionType === 'internal') {
      stepsArray.push({
        id: 'internal-cession-step',
        title: 'Cede tus facturas',
        tooltipTitle:
          'El certificado digital es una herramienta de seguridad que permite firmar documentos digitales con validez legal. Consulta el centro de ayuda para saber cómo adquirir e instalar el certificado. Para más información, contacta a tu ejecutivo/a',
        component: InternalCessionStep,
        props: { selectedProvider },
      });
    } else {
      stepsArray.push({
        id: 'external-cession-step',
        title: 'Ceder desde mi facturador',
        tooltipTitle:
          'Consulta el centro de ayuda para aprender a ceder desde tu facturador. Para más información y manuales, contacta a tu ejecutivo/a',
        component: ExternalCessionStep,
      });
    }

    stepsArray.push({
      id: 'operation-confirmation-step',
      title: '¡Listo!',
      component: ConfirmationStep,
      props: { toggleMenu },
    });

    return stepsArray;
  }, [shouldShowCredentialStep, selectedProvider, selectedCessionType, data]);

  return <ShoppingCartStepper steps={steps} loading={loading} />;
};

OfferShoppingCart.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
};

export default React.memo(OfferShoppingCart);
