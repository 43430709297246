import { PasswordInput } from '@fingo/lib/components/inputs';
import { Button, Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import PropTypes from 'prop-types';
import RutInput from '@fingo/lib/components/inputs/RutInput';
import { LoadingButton } from '@mui/lab';
import { useMutation, useQuery } from '@apollo/client';
import { GET_NATIONAL_INVOICE_PROVIDER, UPLOAD_CREDENTIALS } from '@fingo/lib/graphql';

import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import { useSnackBars } from '@fingo/lib/hooks';

const ThirdStepRegister = ({
  rut,
  setRut,
  password,
  setPassword,
  skip,
}) => {
  const { addAlert } = useSnackBars();
  const country = useGetCountryFromUrl();
  const nationalInvoiceProvider = useQuery(GET_NATIONAL_INVOICE_PROVIDER, {
    variables: { countryId: country?.id },
    skip: !country,
  });
  const [uploadCredentials, { loading }] = useMutation(UPLOAD_CREDENTIALS, {
    variables: {
      username: rut,
      password,
      credentialProvider: nationalInvoiceProvider?.data?.getNationalInvoiceProvider.id,
    },
    onError: (_error) => {
      if (_error.message === 'invalid_credentials') {
        addAlert({ message: 'Credenciales inválidas', id: 'invalid-sii-credentials' });
      } else {
        addAlert({ message: 'Hubo un error al verificar tu empresa. Contáctate con nosotros.', id: 'sii-error' });
      }
    },
    onCompleted: skip,
  });
  const onSubmit = useCallback((event) => {
    event.preventDefault();
    uploadCredentials(event);
  }, []);
  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={2}>
        <Typography>
          Completa tus datos para registrarte.
        </Typography>
        <Typography paragraph gutterBottom>
          Tus credenciales están seguras con nosotros.
        </Typography>
        <RutInput
          onChange={setRut}
          value={rut}
          variant="outlined"
          label="RUT empresa"
          fullWidth
          validationtype="rut"
        />
        <PasswordInput
          onChange={setPassword}
          label="Contraseña del SII"
          variant="outlined"
          fullWidth
          value={password}
        />
        <LoadingButton
          onClick={uploadCredentials}
          variant="contained"
          sx={{
            ml: 'auto !important',
            mr: 'auto !important',
          }}
          size="small"
          endIcon={(
            <ArrowRightAltIcon color="fingoWhite.main" />
          )}
          loading={loading}
        >
          Siguiente
        </LoadingButton>
        <Button
          color="primary"
          onClick={skip}
        >
          Avanzar a la plataforma
        </Button>
      </Stack>
    </form>
  );
};

ThirdStepRegister.propTypes = {
  rut: PropTypes.string.isRequired,
  setRut: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  skip: PropTypes.func,
};

ThirdStepRegister.defaultProps = {
  skip: () => {
    window.history.replace({ pathname: '/app/home' });
  },
};

export default ThirdStepRegister;
