import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import PreofferShoppingSmallUpload from './PreofferShoppingSmallUpload';
import PreofferShoppingBatchUpload from './PreofferShoppingBatchUpload';

const PreofferShoppingUploadDocumentsStep = ({ nextStep, documents, setDocuments,
  selectedPreofferIds }) => {
  const MULTIPLE_DOCUMENTS_LIMIT = 5;
  const multipleDocuments = useMemo(() => (
    selectedPreofferIds.length > MULTIPLE_DOCUMENTS_LIMIT
  ), [selectedPreofferIds]);

  return (
    <>
      {multipleDocuments ? (
        <PreofferShoppingBatchUpload
          nextStep={nextStep}
          setDocuments={setDocuments}
          selectedPreofferIds={selectedPreofferIds}
        />
      ) : (
        <PreofferShoppingSmallUpload
          nextStep={nextStep}
          documents={documents}
          setDocuments={setDocuments}
          selectedPreofferIds={selectedPreofferIds}
        />
      )}
    </>
  );
};
PreofferShoppingUploadDocumentsStep.propTypes = {
  nextStep: PropTypes.func.isRequired,
  documents: PropTypes.objectOf(
    PropTypes.shape({
      documentType: PropTypes.string.isRequired,
      file: PropTypes.instanceOf(window.File).isRequired,
    }),
  ).isRequired,
  setDocuments: PropTypes.func.isRequired,
  selectedPreofferIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PreofferShoppingUploadDocumentsStep;
