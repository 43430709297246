import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { PasswordInput } from '@fingo/lib/components/inputs';
import { UPLOAD_SII_CREDENTIALS, UPDATE_DUMMY_PASSWORD } from '@fingo/lib/graphql';
import { SiiLogo } from '@fingo/lib/assets';
import { useSelectedCompany } from '@fingo/lib/hooks';
import { LoadingButton } from '@mui/lab';
import { Icon, Stack, TextField, Typography } from '@mui/material';

const SiiCredentialsStep = ({ nextStep }) => {
  const [error, setError] = useState('');
  const [inputs, setInputs] = useState({});
  const changeInput = useCallback(
    (inputEvent) => {
      const { value, name } = inputEvent.target;
      setInputs((prev) => ({ ...prev, [name]: value }));
    },
    [],
  );
  const selectedCompany = useSelectedCompany();
  const [uploadCredentials, { loading: uploadingCredentials }] = useMutation(
    UPLOAD_SII_CREDENTIALS,
    {
      variables: { ...inputs, companyId: selectedCompany.id },
      onCompleted: (data) => {
        if (!data.uploadSiiCredentials.success) {
          setError(data.uploadSiiCredentials.message);
        } else {
          nextStep();
        }
      },
    },
  );
  const [updateDummyPassword, { loading: updatingPassword }] = useMutation(
    UPDATE_DUMMY_PASSWORD,
    {
      variables: inputs,
      onCompleted: () => uploadCredentials(),
      onError: (_error) => {
        setError(_error.message);
      },
    },
  );
  const handleSubmit = useCallback(async (submitEvent) => {
    submitEvent.preventDefault();
    updateDummyPassword();
  }, [updateDummyPassword]);
  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2} width={350} alignItems="center" mx="auto">
        <Typography width="100%" align="center" variant="h2">
          Completa tu registro para continuar
        </Typography>
        <TextField
          name="mailProvided"
          value={inputs.mailProvided || ''}
          fullWidth
          onChange={changeInput}
          variant="standard"
          placeholder="Email"
        />
        <TextField
          name="phoneNumber"
          value={inputs.phoneNumber || ''}
          fullWidth
          onChange={changeInput}
          variant="standard"
          placeholder="Número de contacto"
        />
        <PasswordInput
          name="newPassword"
          value={inputs.newPassword || ''}
          fullWidth
          onChange={changeInput}
          variant="standard"
          placeholder="Crea una contraseña"
        />
        <Icon sx={{ height: 30, textAlign: 'left', width: '100%' }}>
          <img src={SiiLogo} alt="sii-icon" style={{ height: '100%' }} />
        </Icon>
        <PasswordInput
          name="siiPassword"
          value={inputs.siiPassword || ''}
          fullWidth
          onChange={changeInput}
          variant="standard"
          placeholder="Ingresa la contraseña del SII de la empresa"
        />
        <Typography variant="body2" align="center" color="error">
          {error}
        </Typography>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={uploadingCredentials || updatingPassword}
          size="small"
          type="submit"
        >
          Continuar
        </LoadingButton>
      </Stack>
    </form>
  );
};

SiiCredentialsStep.propTypes = {
  nextStep: PropTypes.func.isRequired,
};

export default SiiCredentialsStep;
