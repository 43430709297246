import React from 'react';
import PropTypes from 'prop-types';
import { useGetCountryFromUrl } from '@fingo/lib/hooks';
import MexicoOfferShoppingCart from './MexicoOfferShoppingCart';
import ChileOfferShoppingCart from './ChileOfferShoppingCart';

const OfferShoppingCart = ({ toggleMenu }) => {
  const country = useGetCountryFromUrl();

  return country?.name === 'Mexico' ? (
    <MexicoOfferShoppingCart toggleMenu={toggleMenu} />
  ) : (
    <ChileOfferShoppingCart toggleMenu={toggleMenu} />
  );
};

OfferShoppingCart.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
};

export default React.memo(OfferShoppingCart);
