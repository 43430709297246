import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Stack, Box, CircularProgress, Tooltip } from '@mui/material';
import { useInvoicesIds } from '@fingo/lib/hooks';
import { INVOICE_FILES, UPLOAD_INVOICE_DOCUMENTS } from '@fingo/lib/graphql';
import { useMutation } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import ShoppingFileUploadInput from '../ShoppingFileUploadInput';
import ShoppingUploadDocumentsFooter from '../ShoppingUploadDocumentsFooter';

const PreofferShoppingSmallUpload = ({ nextStep, documents, setDocuments,
  selectedPreofferIds }) => {
  const [documentsLoader, setDocumentsLoader] = useState(false);
  const { invoices } = useInvoicesIds(selectedPreofferIds, INVOICE_FILES);

  // Find if an invoice has uploaded documents.
  const filterDocumentsByTypeCode = (invoice, desiredCode) => {
    if (invoice.documents) {
      return invoice.documents.find(
        (document) => document.type.code === desiredCode,
      );
    }
    return [];
  };

  const [uploadFiles] = useMutation(UPLOAD_INVOICE_DOCUMENTS);

  const handleUploadDocuments = () => {
    if (!isEmpty(documents)) {
      setDocumentsLoader(true);
      Object.entries(documents).forEach(([...v]) => {
        let arrayFilesMutation = [];
        arrayFilesMutation = [...arrayFilesMutation,
          { documentType: v[1].documentType, file: v[1].file }];
        const invoiceId = v[0].split('_')[1];
        uploadFiles({
          variables: {
            invoiceId,
            inputUploadFiles: arrayFilesMutation,
          },
        });
      });
    }
  };

  const handleUploadAndNextStep = () => {
    handleUploadDocuments();
    nextStep();
  };

  return (
    <Stack spacing={2} alignItems="center">
      <Typography variant="h6" align="center" mt={1} sx={{ width: '100%' }}>
        ¡Tus facturas fueron enviadas a evaluación!
      </Typography>
      <Typography variant="body2" paragraph gutterBottom align="center" sx={{ width: '100%' }}>
        Te pedimos que subas estos documentos para agilizar el proceso de evaluaión.
      </Typography>
      {invoices.length === 0 ? (
        <Box alignSelf="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2} alignItems="center">
          {/* Grid Header */}
          <Grid item xs={2}>
            <Typography variant="body1" color="primary">Folio:</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body1" color="primary">OC:</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body1" color="primary">PDF de la factura:</Typography>
          </Grid>
          {invoices.map((invoice) => (
            <React.Fragment key={invoice.id}>
              <Grid item xs={2}>
                <Tooltip
                  title={invoice.folio.length > 10 ? invoice.folio : ''}
                  placement="top"
                >
                  <Typography sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    textAlign: 'center',
                    variant: 'body2',
                  }}
                  >
                    {invoice.folio}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={5}>
                <ShoppingFileUploadInput
                  invoiceId={invoice.id}
                  label="PURCHASE_ORDER"
                  documents={documents}
                  setDocuments={setDocuments}
                  uploadedDocuments={filterDocumentsByTypeCode(invoice, 'PURCHASE_ORDER')}
                />
              </Grid>
              <Grid item xs={5}>
                <ShoppingFileUploadInput
                  invoiceId={invoice.id}
                  label="PDF"
                  documents={documents}
                  setDocuments={setDocuments}
                  uploadedDocuments={filterDocumentsByTypeCode(invoice, 'PDF')}
                />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      )}
      <ShoppingUploadDocumentsFooter
        nextStep={handleUploadAndNextStep}
        setDocuments={setDocuments}
        loading={documentsLoader}
      />
    </Stack>
  );
};

PreofferShoppingSmallUpload.propTypes = {
  nextStep: PropTypes.func.isRequired,
  documents: PropTypes.objectOf(
    PropTypes.shape({
      documentType: PropTypes.string.isRequired,
      file: PropTypes.instanceOf(window.File).isRequired,
    }),
  ).isRequired,
  setDocuments: PropTypes.func.isRequired,
  selectedPreofferIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PreofferShoppingSmallUpload;
