import { useQuery } from '@apollo/client';
import { preofferSelectedQuery } from '@fingo/lib/apollo/cache/localQuery';
import { PREOFFER_SHOPPING_CART } from '@fingo/lib/graphql';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import PropTypes from 'prop-types';
import React from 'react';
import useShoppingCartSummary from '@fingo/lib/hooks/useShoppingCartSummary';
import ChilePreofferShoppingStepOne from './ChilePreofferShoppingStepOne';
import MexicoPreofferShoppingStepOne from './MexicoPreofferShoppingStepOne';

const PreofferShoppingStepOneProvider = ({ nextStep, setEvaluatedInvoicesId }) => {
  const { data: { selectedPreofferIds } } = useQuery(preofferSelectedQuery);
  const { data: shoppingCartData, loading: loadingCart } = useQuery(
    PREOFFER_SHOPPING_CART,
    { variables: { invoicesId: selectedPreofferIds } },
  );
  const country = useGetCountryFromUrl();

  const CountryComponent = {
    Mexico: MexicoPreofferShoppingStepOne,
    Chile: ChilePreofferShoppingStepOne,
  }[country?.name];

  return (
    <CountryComponent
      nextStep={nextStep}
      setEvaluatedInvoicesId={setEvaluatedInvoicesId}
      summaryCart={useShoppingCartSummary(shoppingCartData?.preofferShoppingCart)}
      loadingCart={loadingCart}
    />
  );
};

PreofferShoppingStepOneProvider.propTypes = {
  nextStep: PropTypes.func.isRequired,
  setEvaluatedInvoicesId: PropTypes.func.isRequired,
};

export default PreofferShoppingStepOneProvider;
