import { PasswordInput } from '@fingo/lib/components/inputs';
import ValidationTextField from '@fingo/lib/components/inputs/ValidationTextFieldInput';
import { Button, Stack, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import PropTypes from 'prop-types';
import { isPasswordValid, validEmail } from '@fingo/lib/helpers';
import { PasswordStrength } from '../../utils';

const FirstStepRegister = ({
  onCompleted,
  email,
  setEmail,
  password,
  setPassword,
}) => {
  const onSubmit = useCallback((event) => {
    event.preventDefault();
    onCompleted(event);
  }, []);
  const isButtonReady = useMemo(
    () => (validEmail(email) && isPasswordValid(password)),
    [email, password],
  );
  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={2}>
        <Typography paragraph gutterBottom>
          Completa tus datos para registrarte:
        </Typography>
        <ValidationTextField
          onChange={setEmail}
          variant="outlined"
          label="Email"
          fullWidth
          value={email}
        />
        <PasswordInput
          onChange={setPassword}
          value={password}
          label="Contraseña"
          variant="outlined"
          fullWidth
        />
        <PasswordStrength password={password} />
        <Button
          onClick={onCompleted}
          variant="contained"
          sx={{
            ml: 'auto !important',
            mr: 'auto !important',
          }}
          size="small"
          endIcon={(
            <ArrowRightAltIcon color="fingoWhite.main" />
          )}
          disabled={!isButtonReady}
        >
          Siguiente
        </Button>
      </Stack>
    </form>
  );
};
FirstStepRegister.propTypes = {
  onCompleted: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default FirstStepRegister;
