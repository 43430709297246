import React from 'react';
import PropTypes from 'prop-types';
import { StepTypography } from '@fingo/lib/components/typography';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import CessionTypeOption from '../../../options/CessionTypeOption';
import ShoppingCartStepperNavigation from '../../ShoppingCartStepperNaviagtion';
import useCessionTypeOptions from '../../../../hooks/useCessionTypeOptions';
import ShoppingCartSummaryActionButton from '../../ShoppingCartSummary/ShoppingCartSummaryActionButton';

const CessionTypeSelectorStep = ({
  prevStep,
  nextStep,
  selectedCessionType,
  setSelectedCessionType,
  selectedProvider,
  allDocumentsHaveXML,
}) => {
  const options = useCessionTypeOptions(selectedProvider, allDocumentsHaveXML);

  return (
    <>
      <StepTypography stepNumber="2" variant="h6" fontWeight="bold">
        Elige cómo ceder:
      </StepTypography>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="cession-type"
          name="cession-type"
          value={selectedCessionType}
          onChange={(event) => setSelectedCessionType(event.target.value)}
        >
          {options.map(({ value, ...props }) => (
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio color="primary" />}
              labelPlacement="start"
              label={<CessionTypeOption {...props} />}
              sx={{
                backgroundColor: '#f9f9f9',
                border: '1.5px solid #ccc',
                borderRadius: 2,
                marginBottom: 2,
                ml: 0,
                padding: 2,
                justifyContent: 'space-between',
              }}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <ShoppingCartStepperNavigation onBack={prevStep}>
        <ShoppingCartSummaryActionButton
          id="confirm-cession-type-selector-button"
          label="Siguiente"
          disabled={!selectedCessionType}
          onClick={nextStep}
        />
      </ShoppingCartStepperNavigation>
    </>
  );
};

CessionTypeSelectorStep.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  selectedCessionType: PropTypes.string.isRequired,
  setSelectedCessionType: PropTypes.func.isRequired,
  selectedProvider: PropTypes.shape({
    id: PropTypes.string,
    iconUrl: PropTypes.string,
    name: PropTypes.string,
    integrated: PropTypes.bool,
  }),
  allDocumentsHaveXML: PropTypes.bool,
};

CessionTypeSelectorStep.defaultProps = {
  selectedProvider: null,
  allDocumentsHaveXML: false,
};

export default CessionTypeSelectorStep;
