import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography, Box } from '@mui/material';
import UploadDropZoneDialogContainer from '@fingo/lib/components/containers/UploadDropZoneDialogContainer';
import { useSelectedCompany } from '@fingo/lib/hooks';
import { useMutation } from '@apollo/client';
import { UPLOAD_DOCUMENT_BATCH } from '@fingo/lib/graphql';
import ShoppingUploadDocumentsFooter from '../ShoppingUploadDocumentsFooter';

const PreofferShoppingBatchUpload = ({ nextStep, setDocuments, selectedPreofferIds }) => {
  const [error, setError] = useState(null);
  const [ocDocuments, setOcDocuments] = useState([]);
  const [pdfDocuments, setPdfDocuments] = useState([]);
  const [arrayFilesMutation, setArrayFilesMutation] = useState([]);
  const [documentsLoader, setDocumentsLoader] = useState(false);
  const [uploadBatch] = useMutation(UPLOAD_DOCUMENT_BATCH);

  const company = useSelectedCompany();
  const hasInvoiceProvider = company.invoiceProviderCredentials.at(-1)?.credentialProvider;

  const addDocuments = useCallback((files, docType) => {
    setArrayFilesMutation((prevArray) => [
      ...prevArray,
      ...files.map((file) => ({
        documentType: docType,
        file,
      })),
    ]);
  }, []);

  useEffect(() => {
    if (pdfDocuments) {
      addDocuments(pdfDocuments, 'PDF');
    }
  }, [pdfDocuments]);

  useEffect(() => {
    if (ocDocuments) {
      addDocuments(ocDocuments, 'PURCHASE_ORDER');
    }
  }, [ocDocuments]);

  const handleUploadDocuments = () => {
    if (arrayFilesMutation.length > 0) {
      setDocumentsLoader(true);
      uploadBatch({
        variables: {
          invoiceIds: selectedPreofferIds,
          inputUploadFiles: arrayFilesMutation,
        },
        onCompleted: () => {
          setDocumentsLoader(false);
          nextStep();
        },
      });
    } else {
      nextStep();
    }
  };

  return (
    <Stack spacing={2} alignItems="center">
      <Typography variant="h6" align="center" mt={1} sx={{ width: '100%' }}>
        Sube la Orden de Compra{' '}
        {!hasInvoiceProvider && 'y el PDF de la factura'}
      </Typography>
      <Typography variant="body2" paragraph gutterBottom align="center" sx={{ width: '100%' }}>
        Te pedimos que subas estos documentos para agilizar el proceso de evaluaión.
      </Typography>
      <Typography variant="body1" alignSelf="flex-start" color="primary">OC:</Typography>
      <Box sx={{ height: '120px', width: '100%', overflow: 'revert' }}>
        <UploadDropZoneDialogContainer
          files={ocDocuments}
          setFiles={setOcDocuments}
          errorMutation={error}
          setErrorMutation={setError}
          filesType="OC"
        />
      </Box>
      {!hasInvoiceProvider && (
        <>
          <Typography variant="body1" alignSelf="flex-start" color="primary">PDF de la factura:</Typography>
          <Box sx={{ height: '120px', width: '100%', overflow: 'hidden' }}>
            <UploadDropZoneDialogContainer
              files={pdfDocuments}
              setFiles={setPdfDocuments}
              errorMutation={error}
              setErrorMutation={setError}
              filesType="PDF"
            />
          </Box>
        </>
      )}
      <ShoppingUploadDocumentsFooter
        nextStep={handleUploadDocuments}
        setDocuments={setDocuments}
        loading={documentsLoader}
      />
    </Stack>
  );
};

PreofferShoppingBatchUpload.propTypes = {
  nextStep: PropTypes.func.isRequired,
  setDocuments: PropTypes.func.isRequired,
  selectedPreofferIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PreofferShoppingBatchUpload;
