import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useMutation, useReactiveVar } from '@apollo/client';
import { StepTypography } from '@fingo/lib/components/typography';
import SelectBankAccount from '@fingo/lib/components/selects/SelectBankAccount';
import FileInput from '@fingo/lib/components/inputs/FileInput';
import { useCreateOperation, useSelectedCompany } from '@fingo/lib/hooks';
import { UPLOAD_CERTIFICATE } from '@fingo/lib/graphql/customers/mutations';
import { currencyVar } from '@fingo/lib/apollo/reactive-variables';
import ShoppingCartStepperNavigation from '../../ShoppingCartStepperNaviagtion';
import InputHelperText from '../../../helper-texts/InputHelperText';
import ErrorBox from '../../../boxes/ErrorBox';
import ShoppingCartSummaryActionButton from '../../ShoppingCartSummary/ShoppingCartSummaryActionButton';
import { PasswordField } from '../../../fields';

const VALIDATION_SCHEMA = Yup.object({
  certificateFile: Yup.mixed().required(
    'El certificado digital es obligatorio',
  ),
  password: Yup.string().required('La contraseña es obligatoria'),
  bankAccountId: Yup.string().required(
    'Selecciona una cuenta bancaria para realizar el giro',
  ),
});

const InternalCessionStep = ({ prevStep, nextStep, selectedProvider }) => {
  const company = useSelectedCompany();
  const currency = useReactiveVar(currencyVar);
  const { id: companyId, hasDigitalCertificate } = company;
  const [uploadCertificate] = useMutation(UPLOAD_CERTIFICATE);
  const [createOperation] = useCreateOperation(
    undefined, // isExternal
    undefined, // bankId
    undefined, // certificatePassword
    undefined, // onCompleted
    undefined, // setError
    true, // skipCleaning
  );

  const handleFileChange = (event, setFieldValue, setFieldTouched) => {
    const file = event.currentTarget.files[0];
    setFieldValue('certificateFile', file);
    setFieldTouched('certificateFile', true, false);
  };

  const handleSubmit = useCallback(
    (values, { setSubmitting, setFieldError }) => {
      if (!hasDigitalCertificate) {
        uploadCertificate({
          variables: {
            certificatePassword: values.password,
            digitalCertificate: values.certificateFile,
            companyId,
            credentialProviderName: selectedProvider?.name,
          },
          onCompleted: () => {
            createOperation({
              variables: {
                bankAccountId: values.bankAccountId,
                digitalCertificatePassword: values.password,
                isExternal: false,
              },
              onCompleted: () => {
                nextStep();
                setSubmitting(false);
              },
              onError: () => {
                setFieldError(
                  'submit',
                  'Contraseña incorrecta, intenta de nuevo',
                );
                setSubmitting(false);
              },
            });
          },
          onError: () => {
            setFieldError('submit', 'Certificado digital inválido');
            setSubmitting(false);
          },
        });
      } else {
        createOperation({
          variables: {
            bankAccountId: values.bankAccountId,
            digitalCertificatePassword: values.password,
            isExternal: false,
          },
          onCompleted: () => {
            nextStep();
            setSubmitting(false);
          },
          onError: () => {
            setFieldError('submit', 'Contraseña incorrecta, intenta de nuevo');
            setSubmitting(false);
          },
        });
      }
    },
    [
      hasDigitalCertificate,
      uploadCertificate,
      createOperation,
      companyId,
      selectedProvider,
      nextStep,
    ],
  );

  const initialValues = useMemo(
    () => ({
      certificateFile: hasDigitalCertificate || null,
      password: '',
      bankAccountId: '',
    }),
    [hasDigitalCertificate],
  );

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={handleSubmit}
      >
        {({
          setFieldValue,
          setFieldTouched,
          errors,
          touched,
          isSubmitting,
          isValid,
          values,
        }) => (
          <Form
            style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
          >
            <StepTypography stepNumber="4" variant="h6" fontWeight="500" mb={2}>
              Sube tu certificado digital y su clave
            </StepTypography>
            <FileInput
              variant="filled"
              value={values.certificateFile}
              placeholder="Sube un archivo"
              accept=".pfx"
              onChange={(event) => {
                handleFileChange(event, setFieldValue, setFieldTouched);
              }}
            />
            <InputHelperText
              error={touched.certificateFile && errors.certificateFile}
            >
              {errors.certificateFile}
            </InputHelperText>
            <PasswordField />
            <StepTypography stepNumber="5" variant="h6" fontWeight="500" mb={2}>
              Selecciona tu cuenta bancaria donde realizar el giro
            </StepTypography>
            <SelectBankAccount
              setBankId={(id) => setFieldValue('bankAccountId', id)}
              bankId={values.bankAccountId}
              hideText
              currency={currency}
            />
            {errors.submit && <ErrorBox mt={2}>{errors.submit}</ErrorBox>}
            <ShoppingCartStepperNavigation onBack={prevStep}>
              <ShoppingCartSummaryActionButton
                id="confirm-internal-cession-button"
                type="submit"
                label="Operar con Fingo"
                loading={isSubmitting}
                disabled={!isValid || isSubmitting}
              />
            </ShoppingCartStepperNavigation>
          </Form>
        )}
      </Formik>
    </>
  );
};

InternalCessionStep.propTypes = {
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  selectedProvider: PropTypes.shape({
    name: PropTypes.string,
  }),
};

InternalCessionStep.defaultProps = {
  selectedProvider: null,
};
export default InternalCessionStep;
