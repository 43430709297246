import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SummaryStep from './steps/SummaryStep';
import ShoppingCartStepper from '../ShoppingCartStepper';
import ConfirmationStep from './steps/ConfirmationStep';
import ExternalCessionStep from './steps/ExternalCessionStep';

const MexicoOfferShoppingCart = ({ toggleMenu }) => {
  const steps = useMemo(() => {
    const stepsArray = [
      {
        id: 'summary-step',
        title: 'Resumen de operación',
        component: SummaryStep,
      },
      {
        id: 'external-cession-step',
        title: 'Ceder desde mi facturador',
        tooltipTitle:
          'Consulta el centro de ayuda para aprender a ceder desde tu facturador. Para más información y manuales, contacta a tu ejecutivo/a',
        component: ExternalCessionStep,
      },
      {
        id: 'operation-confirmation-step',
        title: '¡Listo!',
        component: ConfirmationStep,
        props: { toggleMenu },
      },
    ];

    return stepsArray;
  }, []);

  return <ShoppingCartStepper steps={steps} />;
};

MexicoOfferShoppingCart.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
};

export default React.memo(MexicoOfferShoppingCart);
