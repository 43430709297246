import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useGetUser } from '@fingo/lib/hooks';
import ShoppingCartStepper from '../ShoppingCartStepper';
import { ConfirmationStep, SiiCredentialsStep, SummaryStep } from './steps';

const OrderingShoppingCart = ({ toggleMenu }) => {
  const { selectedCompany, hasDummyPassword } = useGetUser();

  const showSiiScreen = useMemo(
    () => !selectedCompany.hasSiiCredentials || hasDummyPassword,
    [selectedCompany, hasDummyPassword],
  );

  const steps = [
    ...(showSiiScreen
      ? [{ id: 'ordering-sii-credentials', component: SiiCredentialsStep }]
      : []),
    {
      id: 'ordering-shopping-step-one',
      component: SummaryStep,
    },
    {
      id: 'sent-to-evaluation-cart',
      component: ConfirmationStep,
      props: { toggleMenu },
    },
  ];

  return <ShoppingCartStepper steps={steps} />;
};

OrderingShoppingCart.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
};

export default OrderingShoppingCart;
