import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ShoppingCartStepper from '../ShoppingCartStepper';
import PreofferShoppingStepOneProvider from './PreofferShoppingStepOneProvider';
import PreofferShoppingUploadDocumentsStep from './PreofferShoppingUploadDocumentsStep';
import ConfirmationStep from './steps/ConfirmationStep';

const PreofferShoppingCart = ({ toggleMenu }) => {
  const [documents, setDocuments] = useState({});
  const [evaluatedInvoicesId, setEvaluatedInvoicesId] = useState([]);

  const steps = [
    {
      id: 'preoffer-shopping-one',
      title: 'Resumen de operación',
      component: PreofferShoppingStepOneProvider,
      props: { setEvaluatedInvoicesId },
    },
    {
      id: 'preoffer-shopping-upload-document',
      title: 'Subir documentos',
      component: PreofferShoppingUploadDocumentsStep,
      props: {
        documents,
        setDocuments,
        selectedPreofferIds: evaluatedInvoicesId,
      },
    },
    {
      id: 'preoffer-shopping-send-evaluation',
      title: '¡Listo!',
      component: ConfirmationStep,
      props: { toggleMenu },
    },
  ];

  return <ShoppingCartStepper steps={steps} />;
};

PreofferShoppingCart.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
};

export default PreofferShoppingCart;
