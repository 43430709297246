import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { StepTypography } from '@fingo/lib/components/typography';
import { Box, Typography, Autocomplete } from '@mui/material';
import useGetInvoiceProviders from '../../../../hooks/useGetInvoiceProviders';
import ImageOption from '../../../options/ImageOption';
import ImageInput from '../../../inputs/ImageInput';
import ShoppingCartStepperNavigation from '../../ShoppingCartStepperNaviagtion';
import ShoppingCartSummaryActionButton from '../../ShoppingCartSummary/ShoppingCartSummaryActionButton';

const OTHER_PROVIDER = { id: 'other', name: 'Otro', integrated: false };

const CredentialProviderSelectorStep = ({
  prevStep,
  nextStep,
  selectedProvider,
  setSelectedProvider,
}) => {
  const { providers, loading } = useGetInvoiceProviders();

  const sortedProviders = useMemo(() => {
    const filteredProviders = [...providers]
      .filter((provider) => provider.iconUrl !== null)
      .sort((a, b) => {
        if (a.name === 'MiPyme') return -1;
        if (b.name === 'MiPyme') return 1;
        return 0;
      });
    return [...filteredProviders, OTHER_PROVIDER];
  }, [providers]);

  const handleProviderChange = useCallback(
    (_, newValue) => {
      setSelectedProvider(newValue);
    },
    [setSelectedProvider],
  );

  const renderInput = useCallback(
    (params) => (
      <ImageInput
        {...params}
        label="Seleccione un facturador"
        variant="outlined"
        placeholder="Buscar facturador"
        src={selectedProvider?.iconUrl}
        alt={selectedProvider?.name}
      />
    ),
    [selectedProvider],
  );

  const renderOption = useCallback((props, option) => {
    const { iconUrl: src, name } = option;
    return <ImageOption src={src} alt={name} label={name} {...props} />;
  }, []);

  return (
    <>
      <Box display="inline-flex" alignItems="center">
        <StepTypography
          component="span"
          stepNumber="1"
          variant="h6"
          fontWeight="bold"
        >
          Selecciona tu facturador
        </StepTypography>
        <Typography component="span" ml={1} color="#7C7C7C">
          (Donde emites tus facturas)
        </Typography>
      </Box>
      <Autocomplete
        value={selectedProvider}
        onChange={handleProviderChange}
        options={sortedProviders}
        loading={loading}
        getOptionLabel={(option) => option.name}
        renderOption={renderOption}
        renderInput={renderInput}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        sx={{
          '& .MuiAutocomplete-popupIndicator': { color: 'primary.main' },
          borderRadius: 2,
        }}
      />
      <ShoppingCartStepperNavigation onBack={prevStep}>
        <ShoppingCartSummaryActionButton
          id="confirm-credential-provider-selector-button"
          label="Siguiente"
          disabled={!selectedProvider}
          onClick={nextStep}
        />
      </ShoppingCartStepperNavigation>
    </>
  );
};

CredentialProviderSelectorStep.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  setSelectedProvider: PropTypes.func.isRequired,
  selectedProvider: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    iconUrl: PropTypes.string,
  }),
};

CredentialProviderSelectorStep.defaultProps = {
  selectedProvider: null,
};

export default CredentialProviderSelectorStep;
