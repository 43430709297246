import ValidationTextField from '@fingo/lib/components/inputs/ValidationTextFieldInput';
import { Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';

const SecondStepRegister = ({
  onCompleted,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  phone,
  setPhone,
  loading,
}) => {
  const onSubmit = useCallback((event) => {
    event.preventDefault();
    onCompleted(event);
  }, []);
  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={2}>
        <Typography paragraph gutterBottom>
          Completa tus datos para registrarte:
        </Typography>
        <ValidationTextField
          onChange={setFirstName}
          variant="outlined"
          label="Nombre"
          validationtype="text"
          fullWidth
          value={firstName}
        />
        <ValidationTextField
          onChange={setLastName}
          variant="outlined"
          label="Apellido"
          validationtype="text"
          fullWidth
          value={lastName}
        />
        <ValidationTextField
          onChange={setPhone}
          variant="outlined"
          label="Número de contacto"
          fullWidth
          validationtype="phone"
          value={phone}
        />
        <LoadingButton
          onClick={onCompleted}
          variant="contained"
          sx={{
            ml: 'auto !important',
            mr: 'auto !important',
          }}
          size="small"
          endIcon={(
            <ArrowRightAltIcon color="fingoWhite.main" />
          )}
          loading={loading}
        >
          Avanzar
        </LoadingButton>
      </Stack>
    </form>
  );
};
SecondStepRegister.propTypes = {
  onCompleted: PropTypes.func.isRequired,
  setFirstName: PropTypes.func.isRequired,
  setLastName: PropTypes.func.isRequired,
  setPhone: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};

export default SecondStepRegister;
