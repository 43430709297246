import React from 'react';
import PropTypes from 'prop-types';
import { cleanOrderingShoppingCart } from '@fingo/lib/apollo/reactive-variables/localUpdates';
import { useSimulateOrdering } from '../../../../hooks';
import ShoppingCartSummary from '../../ShoppingCartSummary/ShoppingCartSummary';
import ShoppingCartSummaryActionButton from '../../ShoppingCartSummary/ShoppingCartSummaryActionButton';
import useOrderingShoppingCartSummary from '../../../../hooks/useOrderingShoppingCartSummary';

const SummaryStep = ({ nextStep }) => {
  const { summaryItems, loading, disabled, tooltipTitle } = useOrderingShoppingCartSummary();
  const [sendEvaluate, { loading: loadingEvaluate }] = useSimulateOrdering({
    onCompleted: () => {
      cleanOrderingShoppingCart();
      nextStep();
    },
  });

  return (
    <ShoppingCartSummary
      cartType="ordering"
      summaryItems={summaryItems}
      cleanFunction={cleanOrderingShoppingCart}
      loading={loading}
      actionButton={(
        <ShoppingCartSummaryActionButton
          id="cart-ordering-evaluate"
          onClick={sendEvaluate}
          loading={loadingEvaluate}
          disabled={disabled}
          label="Evaluar"
          tooltipTitle={tooltipTitle}
        />
      )}
    />
  );
};

SummaryStep.propTypes = {
  nextStep: PropTypes.func.isRequired,
};

export default SummaryStep;
